<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="text">
          <div class="line"></div>
          <h2>Full Truck Load</h2>
          <p>Trailers you ask? As we introduce new trailers to our fleet each year, replacing older
            ones and expanding our capacity. You can trust us to deliver your freight to the
            specified destination without hassle with our FTL service safely and promptly. Our
            transportation services are in your best interest, so we make sure that our cargo
            fleet is always equipped with the newest trailer models and latest advancements in
            safe and green technologies.</p>
        </div>
        <div class="images">
          <img
            src="@/assets/services-case1.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  components: {
    Header,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'Services',
          to: '/services',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include services-page()
.section
  .content
    .container
      margin-top: 40px
</style>
