<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container">
        <div class="text">
          <div class="line"></div>
          <h2>Warehousing</h2>
          <p>For safe storage of your freight, we offer you the following:</p>
          <ul>
            <li>
              <div class="square"></div>
              Warehousing: top of the art 130,000 square foot facility</li>
            <li>
              <div class="square"></div>
              Short and long term storage solutions</li>
            <li>
              <div class="square"></div>
              Cross-dock</li>
            <li>
              <div class="square"></div>
              Local drivers to distribute anywhere in the Chicago area</li>
            <li>
              <div class="square"></div>
              30ft ceiling clearance</li>
            <li>
              <div class="square"></div>
              OTR drivers to deliver anywhere in the USA or Canada</li>
            <li>
              <div class="square"></div>
              24/7 Security</li>
          </ul>
        </div>
        <div class="images four">
          <img
            src="@/assets/services-case3-1.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
          <img
            src="@/assets/services-case3-2.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
          <img
            src="@/assets/services-case3-3.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
          <img
            src="@/assets/services-case3-4.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Breadcrumb,
    Footer,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'Services',
          to: '/services',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include services-page()
.section
  .content
    height: calc(100% - 128px - 170px)
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px - 120px)
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    .content
      padding-bottom: 98px
      .breadcrumb-container
        display: none
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    .content
      padding-bottom: 50px
      .breadcrumb-container
        display: none
</style>
