<template>
  <div class="section">
    <div class="bg-video">
      <img src="@/assets/servicesbg.png" alt="" draggable="false">
    </div>
    <div class="main">
      <Header />
      <MobileHeader />
      <div class="content">
        <h1>Transport Services</h1>
        <div class="subheading">
          <p>No matter your freight and destination, our services are just right for you. The
            modern private fleet of transport vehicles we operate presents you with the best
            options to achieve your goals. All of our trucks are run by expert drivers with years
            of experience, ensuring that your freight gets to its destination safely following all
            regulations. Our customized programs that include satellite tracking and
            state-of-the-art safety practices are just a few things that set us apart. We have the
            technical know-how to offer specific plans to meet your needs. Satellite tracking
            allows us to know the location of your shipment at any given moment. We communicate
            this information to you, per your request, via email, text, or phone. This is to
            ensure the safety of your freight. That’s bringing your freight’s safety to your
            table. We offer the following services:</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MobileHeader from '@/components/MobileHeader.vue';

export default {
  components: {
    Header,
    MobileHeader,
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include block0()
.section
  .main
    .content
      h1
        width: 400px
      .subheading
        p
          line-height: 24px
          width: 700px
          margin-top: 60px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .main
      .content
        h1
          width: 500px
        .subheading
          p
            width: 650px
            line-height: 28px
            margin-top: 180px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    .main
      .content
        flex-direction: column
        height: auto
        margin-top: 85px
        text-align: start
        align-items: flex-start
        h1
          width: 290px
          margin-left: 10%
        .subheading
            align-self: flex-end
            margin-right: 10%
            margin-top: 30px
            p
              margin-left: 0px
              font-size: 20px
              line-height: 32px
              width: 614px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    height: 100vh
    .main
      height: 100%
      padding: 0px 15px 40px 15px
      .content
        flex-direction: column
        height: auto
        margin-top: 85px
        text-align: start
        align-items: flex-start
        h1
          width: 290px
          margin-left: 10%
        .subheading
            align-self: flex-end
            margin-right: 10%
            margin-top: 30px
            p
              margin-left: 0px
              font-size: 14px
              width: 249px
</style>
