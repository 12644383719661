<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <Breadcrumb :items="breadcrumbItems" />
      <div class="container reverse">
        <div class="text">
          <div class="line"></div>
          <h2>Refrigerated Van</h2>
          <p>Our refrigerated vans are kept in optimum working condition; the refrigeration units
            ensure regular maintenance and drivers have been expertly trained to provide the
            optimal temperature for your cargo throughout the entire trip. Your shipment will be
            at that temperature you want.</p>
        </div>
        <div class="images">
          <img
            src="@/assets/services-case2.png"
            alt="Allstate Cargo Full Truck Load"
            draggable="false"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  components: {
    Header,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          title: 'Home',
          to: '/',
        },
        {
          title: 'Services',
          to: '/services',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include services-page()
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    .content
      .breadcrumb-container
        display: none
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    .content
      .breadcrumb-container
        display: none
</style>
